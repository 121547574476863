import { ReactComponent as IconMenu } from 'assets/menu.svg'
import styles from './styles/header-menu.module.scss'

const HeaderMenu: React.FunctionComponent = () => {
    const { hamburger } = styles

    return <div className={hamburger}>
        <IconMenu />
        {/* Could be animated now that it's an inline SVG */}
    </div>
}

export default HeaderMenu
