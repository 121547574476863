import { getSites } from 'api/sites'
import SiteList from 'components/SiteList'
import Header from 'components/Header'
import SiteMap from 'components/SiteMap'
import { useEffect, useState } from 'react'
import { Site } from '../../interfaces/Site'
import styles from './styles/portfolio.module.scss'

export default function Portfolio() {
    const {shell, map} = styles

    const [sites, setSites] = useState<Array<Site>>([])
    useEffect(() => { getSites().then(setSites) }, [])

    return (
        <div className={shell}>
            <Header></Header>
            <main>
                <SiteMap className={map} sites={sites}></SiteMap>
                <SiteList sites={sites}></SiteList>
            </main>
        </div>
    )
}
