import { getSite } from 'api/sites'

import { Site as TSite } from '../../interfaces/Site'
import Header from "../../components/Header";

import styles from './styles/site.module.scss'
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SiteMap from "../../components/SiteMap";

export default function SitePage() {
    // State
    const {id} = useParams()
    const [site, setSite] = useState<TSite>()

    // Effects
    useEffect(() => {
        if (!id) return

        getSite(id).then(setSite)
    }, [id])

    // Render
    const {shell, overlay, map} = styles
    return (
        <div className={shell}>
            <Header></Header>
            {site && <main>
                <div className={overlay}>
                    <h1>{site.name}</h1>
                    <p>{site.power / 1_000_000} MW</p>
                </div>
                <SiteMap className={map} sites={[site]}></SiteMap>
            </main>}
        </div>
    )
}
