import React, { useCallback, useEffect, useState } from "react"

import { Site, SitesProps } from "../../interfaces/Site"

import styles from './styles/site-list.module.scss'
import statusGood from '../../assets/status-good.svg'
import statusBad from '../../assets/status-bad.svg'
import clock from '../../assets/clock.svg'
import { NavLink } from "react-router-dom";

// @todo highlight the hovered site in the map

const SiteListItem: React.FunctionComponent<{ site: Site }> = ({site}) => {
    const {listItem, tooltip, services, service} = styles

    const dateOptions: Intl.DateTimeFormatOptions = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
    };

    return <NavLink to={'/site/' + site.id} className={listItem} key={site.id}>
        <header>
            <h2>{site.name}</h2>
            <p>{site.power / 1_000_000} MW</p>
        </header>
        <img alt={site.status} src={site.status === 'Good' ? statusGood : statusBad}/>
        <div className={services}>
            <div className={service + ' ' + site.schedules.now?.service.toLowerCase().replaceAll(/\W/g, '-')}>
                <p className="when">Now</p>
                <p>{site.schedules.now?.service || 'No Schedule'}</p>

                {site.schedules.now && <div className={tooltip}>
                    {/* @todo use an actual tooltip component */}
                    <img alt="Schedule Time"
                         src={clock}
                    />
                    <table>
                        <tr>
                            <th>Start:</th>
                            <td>{new Date(site.schedules.now.start).toLocaleDateString('en-GB', dateOptions)}</td>
                        </tr>
                        <tr>
                            <th>End:</th>
                            <td>{new Date(site.schedules.now.end).toLocaleDateString('en-GB', dateOptions)}</td>
                        </tr>
                    </table>
                </div>}
            </div>
            <div className={service + ' ' + site.schedules.next?.service.toLowerCase().replaceAll(/\W/g, '-')}>
                <p className="when">Next</p>
                <p>{site.schedules.next?.service || 'No Schedule'}</p>

                {site.schedules.next && <div className={tooltip}>
                    <img alt="Schedule Time"
                         src={clock}
                    />
                    <table>
                        <tr>
                            <th>Start:</th>
                            <td>{new Date(site.schedules.next.start).toLocaleDateString('en-GB', dateOptions)}</td>
                        </tr>
                        <tr>
                            <th>End:</th>
                            <td>{new Date(site.schedules.next.end).toLocaleDateString('en-GB', dateOptions)}</td>
                        </tr>
                    </table>
                </div>}
            </div>
        </div>
    </NavLink>
}

const SiteList: React.FunctionComponent<SitesProps> = ({sites}) => {
    // State
    const [sortOrder, setSortOrder] = useState<string>('name')
    const [sortedSites, setSortedSites] = useState<Array<Site>>() // what we'll actually display

    // Functions
    const sortSites = useCallback((order: string) => {
        if (!sites) return

        if (order === 'name') {
            setSortedSites(sites.toSorted((a: Site, b: Site) => a.name.localeCompare(b.name)))
        } else if (order === 'size') {
            setSortedSites(sites.toSorted((a: Site, b: Site) => a.power - b.power))
        } else {
            console.warn('Unrecognized sort order: ' + order + '.')
            setSortedSites(sites)
        }
    }, [sites]);

    // Effects
    useEffect(() => {
        if (!sites) return

        sortSites(sortOrder)
    }, [sites, sortOrder, sortSites])

    // Render
    const {sorter, list} = styles

    return <>
        <div className={sorter}>
            <form action="">
                <fieldset>
                    <legend>Sort By</legend>
                    <select autoFocus id="sort" defaultValue={sortOrder} onChange={e => setSortOrder(e.target.value)}>
                        <option value="name">Name</option>
                        <option value="size">Size</option>
                    </select>
                </fieldset>
            </form>
        </div>
        <div className={list}>
            {sortedSites && sortedSites.map((site) => <SiteListItem site={site} key={site.id}/>)}
        </div>
    </>
}

export default SiteList
