import React, { useEffect, useState } from "react";
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'

import { SitesProps } from "../../interfaces/Site";

import siteGood from 'assets/site-good.svg'
import siteAlert from 'assets/site-alert.svg'

const SiteMap: React.FunctionComponent<SitesProps & { className?: string }> = (
    {sites, className }
) => {
    // Google Map API loader
    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyBDqzhZfpRt2pm87IfhAkqLiGQOQMUif1I"
    } as any)

    // State
    const [markers, setMarkers] = useState<React.ReactElement[]>()
    const [center, setCenter] = useState<google.maps.LatLng>()

    // Effects
    useEffect(() => {
        if (!isLoaded || !sites) return;

        setMarkers(sites.map((site) =>
            <Marker
                key={site.id}
                position={{"lat": site.location.lat, "lng": site.location.lng}}
                icon={site.status === 'Good' ? siteGood : siteAlert}/>));

        const allMarkersBounds = new google.maps.LatLngBounds();
        sites.forEach((site) => allMarkersBounds.extend(site.location));
        setCenter(allMarkersBounds.getCenter());

        // @todo would be better to use the bounds to set the center &
        //  zoom level, but I don't see a way to do that with the existing API
    }, [isLoaded, sites]);

    // Render
    return !isLoaded ? null : (
        <GoogleMap
            mapContainerClassName={className}
            center={center}
            zoom={9}
            options={
                {
                    panControl: false,
                    zoomControl: false,
                    streetViewControl: false,
                    mapTypeControl: false,
                }
            }
        >
            {markers}
        </GoogleMap>
    )
}

export default SiteMap
