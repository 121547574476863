import logo from 'assets/logo.svg'
import { NavLink } from "react-router-dom";
import HeaderMenu from "components/HeaderMenu";
import styles from './styles/header.module.scss'

export interface HeaderProps {
}

const Header: React.FunctionComponent<HeaderProps> = (props) => {
    const {header} = styles

    return <div className={header}>
        <NavLink to="/">
            <img alt="Kraken Flex" height={44} src={logo}/>
        </NavLink>

        <HeaderMenu />
    </div>
}

export default Header
